@import 'style/colors.scss';

.input {
  padding: 10.5px 13px;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  outline: none;
  border-radius: 4px;

  &::-webkit-input-placeholder {
    font-weight: 300;
  }

  &:-ms-input-placeholder {
    font-weight: 300;
  }

  &::placeholder {
    font-weight: 300;
  }

  &.primary {
    background-color: $black;
    border: 1px solid #bfa1ff;
    color: $white;

    &::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.68);
    }

    &:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.68);
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.68);
    }
  }
}
