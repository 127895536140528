@import 'style/colors.scss';
@import 'style/text.scss';

.wrapper {
  padding: 113px 0 96px 0;
  background-color: $white;

  .inner {
    @extend .size-xl;
    color: $black;
  }
}

@media (max-width: 1180px) {
  .wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 40px 0;
  }
}

@media (max-width: 1000px) {
  .wrapper {
    width: 80%;

    h3 {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
}
