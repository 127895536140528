$brand4: #5e36b7;
$brand20: #8159d9;
$brandDark: #130532;
$brandLight: #bfa1ff;
$brandExtraLight: #eee7ff;

$white: #ffffff;
$black: #000000;

$text-secondary: rgba(0, 5, 51, 0.6);
