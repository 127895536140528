.serviceLogo {
  padding-top: 290px;
  min-height: 45rem;
  clip-path: polygon(100% 0, 100% 100%, 24% 89%, 0 85%, 0 0);
  background-color: #eeeeee;
  position: absolute;
  top: 0;
  width: 100vw;
}

.zoomLogo {
  cursor: pointer;
  position: absolute;
  top: 53rem;
  right: 34%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../Hero/assets/zoomLogo.svg');
  height: 1.5rem;
  width: 6.5rem;
}

.ifpsLogo {
  cursor: pointer;
  position: absolute;
  top: 49rem;
  right: 24%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../Hero/assets/ipfsLogo.svg');
  height: 5rem;
  width: 5rem;
}

.googleLogo {
  cursor: pointer;
  position: absolute;
  top: 47rem;
  right: 14%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../Hero/assets/googleLogo.svg');
  height: 4.6rem;
  width: 4rem;
}

.gatherLogo {
  cursor: pointer;
  position: absolute;
  top: 56rem;
  right: 18%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../Hero/assets/gatherLogo.svg');
  height: 2.5rem;
  width: 6.5rem;
}

.cloudflareLogo {
  cursor: pointer;
  position: absolute;
  top: 52.5rem;
  right: 7%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../Hero/assets/cf-logo-v.svg');
  height: 4.5rem;
  width: 8rem;
}

@media (max-width: 1000px) {
  .serviceLogo {
    display: none;
    //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    //min-height: initial;
    //position: static;
    //display: flex;
    //flex-direction: row;
  }

  .zoomLogo {
    display: none;
  }

  .ifpsLogo {
    display: none;
  }

  .googleLogo {
    display: none;
  }

  .gatherLogo {
    display: none;
  }

  .cloudflareLogo {
    display: none;
  }
}
