@import 'style/colors.scss';
@import 'style/text.scss';

.wrapper {
  position: relative;
  padding: 180px 0 113px 0;
  //padding: 120px 0 173px 0;
  min-height: 45rem;
  background-color: #281647;
  background-image: url('./assets/background.png');
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-size: cover;
  clip-path: polygon(100% 0, 100% 62%, 34% 90%, 0 85%, 0 0);

  .zoomLogo,
  .ifpsLogo,
  .googleLogo,
  .cloudflareLogo,
  .gatherLogo {
    display: none;
  }

  .inner {
    //max-width: 1180px;
    margin: 0 auto;

    .topWrapper {
      display: flex;
      align-items: center;

      @media (max-width: 1180px) {
        display: block;
      }
    }
  }
}

@media (max-width: 1250px) {
  .wrapper {
    .inner {
      width: 90%;
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    width: 100%;
    min-height: 100vh;
    padding: 0;
    position: relative;

    .inner {
      width: 80%;
      margin: 0 auto;
      padding: 70px 0;
    }
  }

  .logoContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    margin: 8rem auto 0;
    padding: calc(8vw) 10%;
    //align-items: center;
    position: absolute;
    bottom: 0;
    background-color: #eeeeee;

    .ifpsLogo {
      display: inline;
      background-size: contain;
      background-repeat: no-repeat;
      height: 3.5rem;
      width: 16%;
      //width: 17%;
      //background-image: url('../Hero/assets/ipfsLogo.svg');
    }

    .zoomLogo {
      display: inline;
      background-size: contain;
      background-repeat: no-repeat;
      height: 1.5rem;
      width: 20%;
      //width: 20%;
      //background-image: url('../Hero/assets/zoomLogo.svg');
    }

    .googleLogo {
      display: inline;
      background-size: contain;
      background-repeat: no-repeat;
      height: 3rem;
      margin-top: 0.3rem;
      width: 16%;
      //width: 16%;
      //background-image: url('../Hero/assets/googleLogo.svg');
    }

    .gatherLogo {
      display: inline;
      background-size: contain;
      background-repeat: no-repeat;
      //margin-top: 1.5rem;
      height: 2.6rem;
      width: 24%;
      //width: 20%;
      //background-color: #fff;
      //background-image: url('../Hero/assets/gatherLogo.svg');
    }

    .cloudflareLogo {
      display: inline;
      background-size: contain;
      background-repeat: no-repeat;
      //margin-top: 1rem;
      height: 5.5rem;
      width: 24%;
      //width: 20%;
      //background-color: #fff;
      //background-image: url('../Hero/assets/cf-logo-v.svg');
    }
  }

  @media (max-width: 600px) {
    .wrapper {
      //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      width: 100%;
      min-height: 100vh;
      padding: 0;

      .inner {
        width: 80%;
        margin: 0 auto;
        padding: 70px 0 20px;

        .topWrapper {
        }
      }
    }

    .logoContainer {
      margin: 0 auto;
      padding: calc(3vw) 10%;
      flex-wrap: wrap;

      .ifpsLogo {
        height: 2.3rem;
        width: 33%;
      }

      .zoomLogo {
        height: 1rem;
        width: 33%;
      }

      .googleLogo {
        margin-top: 0.25rem;
        height: 2rem;
        width: 33%;
      }

      .gatherLogo {
        margin-top: 0.35rem;
        height: 1.8rem;
        width: 33%;
      }

      .cloudflareLogo {
        margin-top: 0.35rem;
        height: 3rem;
        width: 33%;
      }
    }
  }
}
