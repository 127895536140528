.size-m {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
}

.size-xl {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
}

.header-1 {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 43px;
  letter-spacing: -0.02em;
}

.header-2 {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.02em;
}
