@import 'style/colors.scss';
@import 'style/text.scss';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  //background-color: rgba(0, 0, 0, 0.54);
  padding: 14px 0 16px 0;

  .logo {
    display: flex;
    align-items: center;
    color: #fff;

    img {
      margin-right: 20px;
      height: 50px;
      width: 50px;
    }

    h1 {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
    }
  }

  .gatewayLink {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .links {
      width: 40%;
      min-width: 30rem;
      display: flex;
      justify-content: space-between;
    }

    .hamburgerMenuContainer {
      display: none;
      //position: relative;

      .menuButton {
        border: none;
        background: transparent;
        height: 3rem;
        width: 3rem;
      }

      .hamburgerMenu {
        background-color: rgba(19, 5, 50, 0.8);
        padding-bottom: 2rem;
        height: 100vh;
        z-index: 10;
        overflow: hidden;

        .menuLink {
          width: 100vw;
          background-color: $brandDark;
          margin: 0;
          padding: 0.8rem 1rem;
          text-align: right;
          cursor: pointer;

          a {
            width: 100%;
          }
        }
      }
    }

    a {
      @extend .size-xl;
      color: #fff;
    }
  }
}

.positionFixed {
  position: fixed;
  background-color: $brandDark;
}

@media (max-width: 1000px) {
  .wrapper {
    .logo {
      img {
        margin-right: 10px;
        height: 30px;
        width: 30px;
      }

      h1 {
        font-size: 1.2rem;
      }
    }

    .gatewayLink {
      .links {
        display: none;
      }

      .hamburgerMenuContainer {
        display: inline-block;

        .hamburgerMenu {
          display: flex;
          flex-direction: column;
          position: fixed;
          right: 0;
        }
      }
    }
  }
}
