@import 'style/colors.scss';
@import 'style/text.scss';

.title {
  color: $brand4;

  &.white {
    color: $white;
  }

  &.secondary {
    color: $text-secondary;
  }

  h3 {
    @extend .header-1;
    margin-top: 0;
  }

  .pretitle {
    @extend .header-2;
    display: block;
    margin-bottom: 19px;
  }
}
