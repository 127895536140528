@import 'style/colors.scss';
@import 'style/text.scss';

.wrapper {
  max-width: 632px;

  .logo {
    display: none;
  }

  .slogan {
    h2 {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 150%;
      //margin-top: 5rem;
      color: $white;
    }
  }

  .description {
    @extend .size-xl;
    width: 93%;
    margin-top: 43px;
    color: $brand20;
  }

  .startButton {
    margin-top: 43px;
  }

  .bottom {
    @extend .header-1;
    margin-top: 101px;
    color: $brand20;
  }
}

@media (max-width: 1250px) {
  .wrapper {
    .slogan {
      h2 {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {
    .logo {
      display: flex;
      align-items: center;

      img {
        width: 60px;
        height: 60px;
        margin-right: 15px;
      }

      h1 {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        font-size: 60px;
        line-height: 150%;
        margin: 0;
        color: $white;
      }
    }

    .slogan {
      h2 {
        margin-top: 3rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .wrapper {
    width: 100%;
    margin: 0 auto;

    .logo {
      margin-bottom: 20px;

      h1 {
        font-size: 10.4vw;
        //font-size: 2.25rem;
      }
    }

    .slogan {
      h2 {
        font-size: 1.2rem;
        margin-top: 2rem;
      }
    }

    .startButton {
      font-size: 0.9rem;
      line-height: 2.5rem;
      padding: 0 1rem;
    }

    .description {
      width: 95%;
      margin-top: 20px;
      font-size: 0.9rem;
      color: white;
      font-weight: 200;
    }
  }
}
